export class VectorSearch {
  private vocabulary: Map<string, number> = new Map();
  private documentVectors: Map<number, Float32Array> = new Map();
  private idfScores: Map<string, number> = new Map();

  public initializeVectors(documents: Array<{ id: number; content: string }>): void {
    this.resetState();
    this.buildVocabulary(documents);
    this.calculateIDFScores(documents.length);
    this.calculateDocumentVectors(documents);
  }

  public getSimilarityScore(documentId: number, queryTerms: string[]): number {
    if (!this.documentVectors.has(documentId)) return 0;

    const queryVector = this.calculateTFIDFVector(queryTerms.join(' '));
    const docVector = this.documentVectors.get(documentId)!;
    return this.calculateCosineSimilarity(queryVector, docVector);
  }

  private resetState(): void {
    this.vocabulary.clear();
    this.documentVectors.clear();
    this.idfScores.clear();
  }

  private buildVocabulary(documents: Array<{ id: number; content: string }>): void {
    documents.forEach(doc => {
      const terms = this.tokenizeContent(doc.content);
      terms.forEach(term => {
        if (!this.vocabulary.has(term)) {
          this.vocabulary.set(term, this.vocabulary.size);
          this.idfScores.set(term, 0);
        }
        this.idfScores.set(term, (this.idfScores.get(term) || 0) + 1);
      });
    });
  }

  private calculateIDFScores(totalDocs: number): void {
    this.idfScores.forEach((docFreq, term) => {
      this.idfScores.set(term, Math.log(totalDocs / docFreq + 1));
    });
  }

  private calculateDocumentVectors(documents: Array<{ id: number; content: string }>): void {
    documents.forEach(doc => {
      const vector = this.calculateTFIDFVector(doc.content);
      this.documentVectors.set(doc.id, vector);
    });
  }

  private calculateTFIDFVector(text: string): Float32Array {
    const vector = new Float32Array(this.vocabulary.size).fill(0);
    const terms = this.tokenizeContent(text);
    
    const termFreq = new Map<string, number>();
    terms.forEach(term => {
      termFreq.set(term, (termFreq.get(term) || 0) + 1);
    });

    termFreq.forEach((freq, term) => {
      const index = this.vocabulary.get(term);
      if (index !== undefined) {
        const tf = freq / terms.length;
        const idf = this.idfScores.get(term) || 0;
        vector[index] = tf * idf;
      }
    });

    return vector;
  }

  private calculateCosineSimilarity(vecA: Float32Array, vecB: Float32Array): number {
    let dotProduct = 0;
    let normA = 0;
    let normB = 0;

    for (let i = 0; i < vecA.length; i++) {
      dotProduct += vecA[i] * vecB[i];
      normA += vecA[i] * vecA[i];
      normB += vecB[i] * vecB[i];
    }

    normA = Math.sqrt(normA);
    normB = Math.sqrt(normB);

    return dotProduct / (normA * normB) || 0;
  }

  private tokenizeContent(content: string): string[] {
    return content
      .toLowerCase()
      .replace(/[^\w\s]/g, ' ')
      .split(/\s+/)
      .filter(word => word.length > 2);
  }
}
