import { GitHubIssue } from "./github-types";
import { TaskManager } from "./task-manager";
import { SearchResult, SearchWeights, SearchConfig } from "./types/search-types";
import { VectorSearch } from "./search/vector-search";
import { SearchScorer } from "./search/search-scorer";

export class IssueSearch {
  private readonly searchableProperties = ["title", "body", "number", "html_url"] as const;
  private readonly weights: SearchWeights = {
    title: 0.3,
    body: 0.2,
    fuzzy: 0.2,
    meta: 0.1,
    vector: 0.2
  };
  
  private readonly config: SearchConfig = {
    fuzzySearchThreshold: 0.7,
    exactMatchBonus: 1.0,
    fuzzyMatchWeight: 0.7
  };

  private readonly vectorSearch: VectorSearch;
  private readonly searchScorer: SearchScorer;

  constructor(private taskManager: TaskManager) {
    this.vectorSearch = new VectorSearch();
    this.searchScorer = new SearchScorer(this.config);
  }

  public initializeVectors(issues: GitHubIssue[]): void {
    const documents = issues.map(issue => ({
      id: issue.id,
      content: this.getSearchableContent(issue)
    }));
    this.vectorSearch.initializeVectors(documents);
  }

  public search(searchText: string, issueIds: number[]): Map<number, SearchResult> {
    const filterText = searchText.toLowerCase().trim();
    const results = new Map<number, SearchResult>();

    if (!filterText) {
      issueIds.forEach(id => results.set(id, this.createEmptyResult()));
      return results;
    }

    const searchTerms = this.preprocessSearchTerms(filterText);

    issueIds.forEach(issueId => {
      const issue = this.taskManager.getGitHubIssueById(issueId);
      if (!issue) {
        results.set(issueId, this.createEmptyResult(false));
        return;
      }

      const result = this.calculateIssueRelevance(issue, searchTerms);
      results.set(issueId, result);
    });

    this.calculateNDCGScore(results);
    return results;
  }

  private calculateIssueRelevance(
    issue: GitHubIssue,
    searchTerms: string[]
  ): SearchResult {
    const matchDetails = {
      titleMatches: [] as string[],
      bodyMatches: [] as string[],
      labelMatches: [] as string[],
      numberMatch: false,
      similarityScore: 0,
      fuzzyMatches: [] as Array<{
        original: string;
        matched: string;
        score: number;
      }>
    };

    const searchableContent = this.getSearchableContent(issue);

    // Calculate individual scores
    const scores = {
      title: this.searchScorer.calculateTitleScore(issue, searchTerms, matchDetails),
      body: this.searchScorer.calculateBodyScore(issue, searchTerms, matchDetails),
      fuzzy: this.searchScorer.calculateFuzzyScore(searchableContent, searchTerms, matchDetails),
      meta: this.searchScorer.calculateMetaScore(issue, searchTerms, matchDetails),
      vector: this.vectorSearch.getSimilarityScore(issue.id, searchTerms)
    };

    matchDetails.similarityScore = scores.vector;

    // Calculate weighted total score
    const totalScore = Object.entries(scores).reduce((total, [key, score]) => {
      return total + score * this.weights[key as keyof SearchWeights];
    }, 0);

    const isVisible = totalScore > 0 || matchDetails.numberMatch;

    return {
      visible: isVisible,
      score: isVisible ? totalScore : 0,
      matchDetails
    };
  }

  private calculateNDCGScore(results: Map<number, SearchResult>): number {
    const scores = Array.from(results.values())
      .filter(r => r.visible)
      .map(r => r.score)
      .sort((a, b) => b - a);

    if (scores.length === 0) return 0;

    const dcg = scores.reduce((sum, score, index) => {
      return sum + (Math.pow(2, score) - 1) / Math.log2(index + 2);
    }, 0);

    const idcg = [...scores]
      .sort((a, b) => b - a)
      .reduce((sum, score, index) => {
        return sum + (Math.pow(2, score) - 1) / Math.log2(index + 2);
      }, 0);

    return idcg === 0 ? 0 : dcg / idcg;
  }

  private preprocessSearchTerms(searchText: string): string[] {
    return searchText
      .split(/\s+/)
      .filter(Boolean)
      .map(term => term.toLowerCase());
  }

  private getSearchableContent(issue: GitHubIssue): string {
    return `${issue.title} ${issue.body || ''} ${
      issue.labels?.map(l => typeof l === 'object' && l.name ? l.name : '').join(' ') || ''
    }`.toLowerCase();
  }

  private createEmptyResult(visible: boolean = true): SearchResult {
    return {
      visible,
      score: visible ? 1 : 0,
      matchDetails: {
        titleMatches: [],
        bodyMatches: [],
        labelMatches: [],
        numberMatch: false,
        similarityScore: 0,
        fuzzyMatches: []
      }
    };
  }
}
